import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "styles/css/admin.css";
import "styles/css/blog.css";
import "styles/css/dashboard.css";
import "styles/css/style.css";

/* Lazy loaded Components */
const LoadingAnimation = lazy(() => import("common/LoadingAnimation"));
// const CourseDetails = lazy(() => import("pages/CourseDetails"));
const NotFound = lazy(() => import("common/NotFound"));
// const CourseVideo = lazy(() => import("components/user/CourseVideo"));
// const MyCourses = lazy(() => import("components/user/MyCourses"));
// const ProtectedRoute = lazy(() => import("common/ProtectedRoute"));
// const AccountBox = lazy(() => import("components/accountBox"));
// const AdminRoute = lazy(() => import("common/AdminRoute"));
// const AdminLogin = lazy(() => import("pages/admin/AdminLogin"));
// const Blog = lazy(() => import("pages/Blog"));
// const Brands = lazy(() => import("pages/Brands"));
// const Ceo = lazy(() => import("pages/Ceo"));
// const Contact = lazy(() => import("pages/Contact"));
// const CoreValues = lazy(() => import("pages/CoreValues"));
// const CorporateOverview = lazy(() => import("pages/CorporateOverview"));
// const Courses = lazy(() => import("pages/Courses"));
// const Category = lazy(() => import("pages/courses/Category"));
// const AdminDashboard = lazy(() => import("pages/dashboard/AdminDashboard"));
// const UploadPost = lazy(() => import("pages/dashboard/UploadPost"));
// const UploadVideo = lazy(() => import("pages/dashboard/UploadVideo"));
const Main = lazy(() => import("pages/Main"));
// const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
// const Products = lazy(() => import("pages/Products"));
// const Services = lazy(() => import("pages/Services"));
// const Team = lazy(() => import("pages/Team"));
// const UploadCategory = lazy(() => import("pages/UploadCategory"));
// const UploadCourse = lazy(() => import("pages/UploadCourse"));
// const UserDashboard = lazy(() => import("pages/UserDashboard"));
// const VisionAndMission = lazy(() => import("pages/VisionAndMission"));
// const ManageUsers = lazy(() => import("pages/ManageUsers"));

function App() {
  return (
    <Suspense fallback={<LoadingAnimation loading={true} />}>
      <ToastContainer />

      <Routes>
        {/* <Route path="/auth/user" element={<AccountBox />} /> */}
        {/* <Route path="/auth/admin" element={<AdminLogin />} /> */}

        {/* <Route
          path="/upload/video"
          element={
            <AdminRoute>
              <UploadVideo />
            </AdminRoute>
          }
        /> */}

        {/* <Route
          path="/upload/category"
          element={
            <AdminRoute>
              <UploadCategory />
            </AdminRoute>
          }
        />

        <Route
          path="/upload/course"
          element={
            <AdminRoute>
              <UploadCourse />
            </AdminRoute>
          }
        />

        <Route
          path="/upload/post"
          element={
            <AdminRoute>
              <UploadPost />
            </AdminRoute>
          }
        />

        <Route
          path="/users/manage"
          element={
            <AdminRoute>
              <ManageUsers />
            </AdminRoute>
          }
        /> */}

        {/* 

        <Route path="/courses/:categoryName" element={<Category />} /> */}

        {/* <Route
          path="/courses/:categoryName/:courseName"
          element={
            <ProtectedRoute>
              <CourseDetails />
            </ProtectedRoute>
          }
        /> */}

        {/* <Route
          path="/my/courses/:courseName"
          element={
            <ProtectedRoute>
              <MyCourses />
            </ProtectedRoute>
          }
        /> */}

        {/* <Route
          path="/my/courses/:courseName/:lectureName"
          element={
            <ProtectedRoute>
              <CourseVideo />
            </ProtectedRoute>
          }
        /> */}

        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <UserDashboard />
            </ProtectedRoute>
          }
        /> */}

        {/* 
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <AdminDashboard />
            </AdminRoute>
          }
        /> */}

        {/* <Route path="/courses" element={<Courses />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/ceo" element={<Ceo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/team" element={<Team />} />
        <Route path="/vision-and-mission" element={<VisionAndMission />} />
        <Route path="/corporate-overview" element={<CorporateOverview />} />
        <Route path="/core-values" element={<CoreValues />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/brands" element={<Brands />} /> */}
        <Route path="/" exact element={<Main />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
