/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import App from "./App";
import logrocketConfig from "config/logrocket";

import "./index.css";
import { AdminProvider } from "context/AdminContext";
import { UserProvider } from "context/UserContext";

logrocketConfig();

const root = ReactDOM.createRoot(document.getElementById("app-root"));

root.render(
  <Router>
    <AdminProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </AdminProvider>
  </Router>
);
