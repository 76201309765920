/* eslint-disable import/no-anonymous-default-export */
import jwtDecode from "jwt-decode";
import http from "./httpService";

const tokenKey = "token";
const adminTokenKey = "admin";
const authTokenKey = "auth-token";

const getJwt = () => {
  return localStorage.getItem(tokenKey);
};

http.setJwt(getJwt());

const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(authTokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
};
const currentUser = getCurrentUser();

const getAdminUser = () => {
  try {
    const jwt = localStorage.getItem(adminTokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
};
const adminUser = getAdminUser();

const login = async (userObj) => {
  const response = await http.post(`/auth/login`, {
    email: userObj.email,
    password: userObj.password,
  });

  if (response.ok) {
    localStorage.setItem(authTokenKey, response.data.token);
  }

  return response;
};

export const loginWithJwt = (jwt) => {
  localStorage.setItem(tokenKey, jwt);
};

const logout = () => {
  localStorage.removeItem(tokenKey);
};

export default {
  adminUser,
  currentUser,
  login,
  logout,
  loginWithJwt,
};
